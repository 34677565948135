import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { forwardRef } from 'react';
import { ThemeButtonKeys } from '../../styles/tokens/buttons';
import { fontWeight } from '../../styles/tokens/typography';
import { useButtonStyles } from '../../hooks';
import { ArrowLeft, ArrowRight } from '../icon';
import { LinkTo } from '../linkTo/LinkTo';

export type ButtonType = HTMLButtonElement | HTMLAnchorElement;

export interface ButtonProps
  extends Partial<Omit<HTMLButtonElement, 'children'>>,
    Partial<
      Omit<
        HTMLAnchorElement,
        'addEventListener' | 'removeEventListener' | 'children'
      >
    > {
  variant?: ThemeButtonKeys;
  label?: string;
  url?: string;
  as?: string;
  a11yTitle?: string;
  dataTest?: string;
  arrowRight?: boolean;
  arrowLeft?: boolean;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
  onClick?: React.MouseEventHandler<ButtonType>;
  small?: boolean;
  children?: React.ReactNode | string;
  isLoading?: boolean;
  state?: any;
  notFullWidth?: boolean;
  useContainerQuery?: boolean;
  noPadding?: boolean;
}

type StyledButtonProps = Pick<
  ButtonProps,
  'variant' | 'disabled' | 'small' | 'isLoading'
> & {
  iconOnly?: boolean;
  useContainerQuery?: boolean;
};

type ButtonTypes = React.ReactNode | React.PropsWithChildren<any> | string;

const rotateAni = keyframes`
  0% {
    transform: translatex(-50%) translatey(-50%) rotate(0deg);
  }
  100% {
    transform: translatex(-50%) translatey(-50%) rotate(360deg);
  }
  `;

// Fixes the React does not recognize the `XXX` prop on a DOM element.
const BLACKLISTED_PROPS = [
  'iconOnly',
  'small',
  'isAdded',
  'as',
  'alignTop',
  'rotateIcon',
  'isLoading',
  'dataTest',
  'a11yTitle',
  'notFullWidth',
];
// test
export const StyledButton = styled('a', {
  shouldForwardProp: (propName) =>
    // If not in black list then return true
    !BLACKLISTED_PROPS.includes(propName),
})<StyledButtonProps>`
  ${({
    theme: { space, media, borderRadii, colors },
    variant,
    small,
    iconOnly,
    isLoading,
    notFullWidth,
    useContainerQuery,
    noPadding,
  }) => {
    // Size

    let padding = space.small;
    let paddingDesktop = `${space.small} ${space.large}`;

    if (iconOnly) {
      padding = space.small;
    }

    if (small && !iconOnly) {
      padding = space.xSmall;
      paddingDesktop = `${space.xSmall} ${space.medium}`;
    }

    if (small && iconOnly) {
      padding = space.xxSmall;
    }

    if (noPadding) {
      padding = space.none;
      paddingDesktop = '0';
    }

    return css`
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      gap: ${space.xxSmall};
      padding: ${padding};
      border: solid 2px;
      text-decoration: none;
      font-weight: ${fontWeight.bold};
      ${variant && useButtonStyles(variant)};
      border-radius: ${borderRadii.medium};
      max-height: ${small ? space.xLarge : space.xxLarge};
      flex-shrink: 0;
      width: ${notFullWidth ? 'auto' : '100%'};

      &:focus {
        outline: none;
        box-shadow: 0 0 0 4px ${colors.systemFocus};
      }

      &:disabled {
        cursor: default;
        opacity: 0.2;
        pointer-events: none;
      }

      @media (min-width: ${media.small}) {
        width: auto;
      }

      ${!iconOnly &&
      css`
        @media (min-width: ${media.medium}) {
          padding: ${paddingDesktop};
        }
      `}

      ${isLoading &&
      css`
        position: relative;
        transition: all 0.3s linear;
        color: transparent;
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: ${space.medium};
          height: ${space.medium};
          border: 2px solid;
          border-radius: 50%;
          border-top-color: rgba(255, 255, 255, 0.7);
          border-left-color: rgba(255, 255, 255, 0.1);
          border-right-color: rgba(255, 255, 255, 0.5);
          border-bottom-color: rgba(255, 255, 255, 0.3);
          animation: ${rotateAni} 0.6s linear infinite;
        }
      `}

    ${small &&
      css`
        svg {
          width: ${space.medium};
          height: ${space.medium};
        }
      `}

    ${useContainerQuery &&
      css`
        @container (max-width: ${media.small}) {
          width: 100%;
        }
      `}
    `;
  }}
`;

export const Button = forwardRef<ButtonType, ButtonProps>(
  (
    {
      children,
      a11yTitle,
      onClick,
      disabled = false,
      variant = 'primary',
      url,
      as,
      label,
      iconLeft,
      iconRight,
      arrowRight,
      arrowLeft,
      small,
      isLoading,
      dataTest,
      target,
      state,
      notFullWidth,
      useContainerQuery,
      noPadding,
      ...rest
    },
    ref
  ) => {
    // Handle click

    const handleClick = onClick;

    // Icons

    const IconLeft = iconLeft;
    const IconRight = iconRight;

    // Component as

    let component: ButtonTypes;

    if (as) {
      component = as;
    } else if (!url) {
      component = 'button';
    } else {
      component = LinkTo;
    }

    const iconOnly: boolean =
      (!!arrowLeft || !!arrowRight || !!iconRight || !!iconLeft) &&
      !label &&
      !children;

    // const StyledButtonComponent = StyledButton.withComponent(component);
    return (
      <StyledButton
        ref={ref}
        to={url}
        as={component}
        onClick={handleClick}
        aria-label={a11yTitle}
        variant={variant}
        disabled={disabled}
        aria-disabled={disabled}
        target={target}
        iconOnly={iconOnly}
        small={small}
        isLoading={isLoading}
        state={state}
        {...(dataTest && { 'data-test': dataTest })}
        notFullWidth={notFullWidth}
        useContainerQuery={useContainerQuery}
        noPadding={noPadding}
        {...rest}
      >
        {arrowLeft && <ArrowLeft />}
        {iconLeft && !arrowLeft ? IconLeft : ''}
        {children || label}
        {iconRight && !arrowRight ? IconRight : ''}
        {arrowRight && <ArrowRight />}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';

export default Button;
